var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"social xs-hide"},[_c('ul',[_c('li',{staticClass:"hover_image"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"26px","height":"19px"},attrs:{"loading":"lazy","alt":"Icono Youtube","src":require("../../assets/Home/Iconos/youtube.webp")},on:{"click":function($event){return _vm.win.open(
            'https://www.youtube.com/@CanalMunicipalidadArica',
            '_blank'
          )}}}),_c('img',{staticClass:"cursor-pointer hide",staticStyle:{"width":"26px","height":"19px"},attrs:{"loading":"lazy","alt":"Icono Youtube","src":require("../../assets/Home/Iconos/youtube-color.webp")},on:{"click":function($event){return _vm.win.open(
            'https://www.youtube.com/@CanalMunicipalidadArica',
            '_blank'
          )}}})]),_c('li',{staticClass:"hover_image"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"26px","height":"27px"},attrs:{"loading":"lazy","alt":"Icono Instagram","src":require("../../assets/Home/Iconos/insta.webp")},on:{"click":function($event){return _vm.win.open('https://www.instagram.com/muniarica/', '_blank')}}}),_c('img',{staticClass:"cursor-pointer hide",staticStyle:{"width":"26px","height":"27px"},attrs:{"loading":"lazy","alt":"Icono Instagram","src":require("../../assets/Home/Iconos/insta-color.webp")},on:{"click":function($event){return _vm.win.open('https://www.instagram.com/muniarica/', '_blank')}}})]),_c('li',{staticClass:"hover_image"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"26px","height":"27px"},attrs:{"loading":"lazy","alt":"Icono Facebook","src":require("../../assets/Home/Iconos/face.webp")},on:{"click":function($event){return _vm.win.open('https://www.facebook.com/MunicipalidaddeArica', '_blank')}}}),_c('img',{staticClass:"cursor-pointer hide",staticStyle:{"width":"26px","height":"27px"},attrs:{"loading":"lazy","alt":"Icono Facebook","src":require("../../assets/Home/Iconos/face-color.webp")},on:{"click":function($event){return _vm.win.open('https://www.facebook.com/MunicipalidaddeArica', '_blank')}}})]),_c('li',{staticClass:"hover_image"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"26px","height":"26px"},attrs:{"loading":"lazy","alt":"Icono Tiktok","src":require("../../assets/Home/Iconos/tiktok.webp")},on:{"click":function($event){return _vm.win.open(
            'https://www.tiktok.com/@aricamunicipalidad',
            '_blank'
          )}}}),_c('img',{staticClass:"cursor-pointer hide",staticStyle:{"width":"26px","height":"26px"},attrs:{"loading":"lazy","alt":"Icono tiktok","src":require("../../assets/Home/Iconos/tiktok-color.webp")},on:{"click":function($event){return _vm.win.open(
            'https://www.tiktok.com/@aricamunicipalidad',
            '_blank'
          )}}})]),_c('li',{staticClass:"hover_image"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"26px","height":"11px"},attrs:{"loading":"lazy","alt":"Icono Flicker","src":require("../../assets/Home/Iconos/flicker.webp")},on:{"click":function($event){return _vm.win.open('https://www.flickr.com/photos/muniarica/albums', '_blank')}}}),_c('img',{staticClass:"cursor-pointer hide",staticStyle:{"width":"26px","height":"11px"},attrs:{"loading":"lazy","alt":"Icono Flicker","src":require("../../assets/Home/Iconos/flicker-color.webp")},on:{"click":function($event){return _vm.win.open('https://www.flickr.com/photos/muniarica/albums', '_blank')}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }